















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import VesselSponsoredTable from "@/components/vessel/VesselSponsoredTable.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import VesselCreateDialog from "@/components/dialogs/VesselCreateDialog.vue";
import { VesselModel } from "@/api/generated";
import FileDownloadUtility from "@/utility/fileDownloadUtility";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: { VesselCreateDialog, VesselSponsoredTable }
})
export default class VesselOverview extends Vue {
  private tableRefreshKey = false;
  private loading = false;

  private onVesselUpdate(item: VesselModel) {
    if (!item.vesselId) {
      return;
    }

    this.$router.push({
      name: "Vessel Edit",
      params: { vesselId: item.vesselId }
    });
  }

  private async onVesselDelete(item: VesselModel) {
    if (!item.vesselId) {
      return;
    }

    try {
      await Api.VesselService.apiVesselVesselIdDelete(item.vesselId);
      snackbarModule.setSnackbarMessage("Vessel deleted");
      this.tableRefreshKey = !this.tableRefreshKey;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete vessel");
    }
  }

  private async onDownloadTermsConditions(item: VesselModel) {
    try {
      this.loading = true;
      if (item.vesselId != null) {
        const response = await Api.ReportService.apiReportVesseltermsconditionsIdGet(
          item.vesselId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.name} ${item.registrationNumber} T&C.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }
  private async onDownloadOwnerInstruction(item: VesselModel) {
    try {
      this.loading = true;
      if (item.vesselId != null) {
        const response = await Api.ReportService.apiReportVesselownerinstructionIdGet(
          item.vesselId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.name} ${item.registrationNumber} Power Of Attorney.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }

  private async onDownloadVmsRequestForm(item: VesselModel) {
    try {
      this.loading = true;
      if (item.vesselId != null) {
        const response = await Api.ReportService.apiReportVesselvmsrequestformIdGet(
          item.vesselId
        );
        FileDownloadUtility.DownloadFile(
          response.data,
          `${item.name} ${item.registrationNumber} VMS Request Form.docx`
        );
      }
    } finally {
      this.loading = false;
    }
  }
}
