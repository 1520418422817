





























































import { Vue, Component } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { VesselModel } from "@/api/generated";
import nameof from "@/utility/nameof";
import UserModule from "@/store/userModule";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class VesselDataTable extends Vue {
  private loading = false;
  private vessels: Array<VesselModel> = [];
  private headers: Array<DataTableHeader<VesselModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Vessel", value: nameof<VesselModel>("name") },
    { text: "Vessel Type", value: nameof<VesselModel>("vesselTypeName") },
    { text: "IMO", value: nameof<VesselModel>("imoNumber") },
    { text: "Contact Name", value: nameof<VesselModel>("contactName") },
    {
      text: "Contact Telephone",
      value: nameof<VesselModel>("telephoneNumber")
    },
    { text: "Contact Email", value: nameof<VesselModel>("emailAddress") },
    { text: "Agency", value: nameof<VesselModel>("agencyName") },
    {
      text: "Sponsorship Number",
      value: nameof<VesselModel>("sponsorshipLicenseNumber")
    },
    {
      text: "Current Contract Start",
      value: nameof<VesselModel>("currentContractStartDate")
    },
    {
      text: "Current Contract End",
      value: nameof<VesselModel>("currentContractEndDate")
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getSponsoredVessels();
  }

  private async getSponsoredVessels() {
    try {
      this.loading = true;
      if (userModule.role == "AGM") {
        const response = await Api.VesselService.apiVesselByagencySponsoredUserIdGet(
          userModule.userId
        );
        this.vessels = response.data;
      } else {
        const response = await Api.VesselService.apiVesselSponsoredGet();
        this.vessels = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessels");
    } finally {
      this.loading = false;
    }
  }

  private updateNote(item: VesselModel) {
    return item.contactVessel ? "error--text" : "";
  }
}
